import axios from "axios";
import { profileSlice } from "../redux/slices/profile/slice";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

export const userDisconnect = async (tonConnectUI, dispatch) => {
  try {
    await instance.post("token/logout");
    //  console.log("logout:", response);
  } catch (error) {
    //console.error("Error fetching authentication status:", error);
  }
  tonConnectUI.disconnect();
  dispatch(profileSlice.actions.clearProfile());
};

// export const getUserInfo = async () => {
//   try {
//     const response = await instance.get("profile/me");
//     return response.data;
//   } catch (error) {
//     //console.error("Error get user info:", error);
//   }
// };
