const DepositPage = ({
  value,
  handleAmountChange,
  bruttoValue,
  wallet,
  dispatch,
  handleSendTransaction,
  tonConnectUi,
}) => {
  return (
    <div className="send-tx-form">
      <div className="input-group">
        <label htmlFor="myInput">Add TON</label>
        <input
          type="number"
          id="amount"
          value={value}
          min="0"
          onChange={(e) => handleAmountChange(e.target.value)}
        />
        <p className="input-hint">
          *{bruttoValue.toFixed(2)} TON will be totally charged, including 1%
          fee
        </p>
      </div>
      {wallet ? (
        <button
          disabled={bruttoValue === 0}
          onClick={() => handleSendTransaction(dispatch)}
          className="shadow-outer text-violet bg-acid hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-violet font-medium text-m rounded-lg
                    px-2 py-2 focus:outline-none lg:px-5 lg:py-[10px] disabled:shadow-none disabled:bg-pressedviolet disabled:text-uf"
        >
          Transfer
        </button>
      ) : (
        <button onClick={() => tonConnectUi.openModal()}>
          Connect wallet to send the transaction
        </button>
      )}
    </div>
  );
};

export default DepositPage;
