import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "./components/modal/modal";
import { selectModalContent } from "./redux/slices/modal/selector";
import { setModalContent } from "./redux/slices/modal/slice";

import { TonProof } from "./TonProof";
import SendModal from "./components/send_modal/sendmodal";
import { selectSendModalContent } from "./redux/slices/sendModal/selector";
import { setSendModalContent } from "./redux/slices/sendModal/slice";

const MainPage = () => {
  const modalContent = useSelector(selectModalContent);
  const sendModalContent = useSelector(selectSendModalContent);
  const dispatch = useDispatch();
  return (
    <>
      <SendModal
        isOpen={sendModalContent.isOpen}
        onClose={() => dispatch(setSendModalContent(false))}
      ></SendModal>
      <Modal
        isOpen={modalContent.isOpen}
        onClose={() => dispatch(setModalContent(false))}
      >
        <h2>{modalContent.title}</h2>
        <p>{modalContent.message}</p>
      </Modal>

      <TonProof />
    </>
  );
};
export default MainPage;
