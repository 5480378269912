import "./sendmodal.css";
import React, { useState } from "react";
import { ReactComponent as Close } from "../../assets/close.svg";
import { Transition } from "react-transition-group";
import { useTonConnectUI, useTonWallet } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import { setModalContent } from "../../redux/slices/modal/slice";
import { selectUserInfo } from "../../redux/slices/profile/selectors";

import DepositPage from "./modalPages/deposit";
import { makeWithdraw } from "../../redux/slices/lotto6x45/slice";
import WithdrawPage from "./modalPages/withdraw";

const defaultTx = {
  // The transaction is valid for 10 minutes from now, in unix epoch seconds.
  validUntil: Math.floor(Date.now() / 1000) + 600,
  messages: [
    {
      address: process.env.REACT_APP_MAIN_ADDRESS,
      amount: "10000000000",
    },

    {
      address: process.env.REACT_APP_FEE_ADDRESS,
      amount: "100000000",
    },
  ],
};

const SendModal = ({ isOpen, onClose }) => {
  const userInfo = useSelector(selectUserInfo);
  const [tx, setTx] = useState(defaultTx);
  const [activeTab, setActiveTab] = useState("deposit");
  const [bruttoValue, setBruttoValue] = useState(10.1);
  const [isWithdrawDisabled, setIsWithdrawDisabled] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState(
    userInfo?.balance / 10 ** 9
  );
  const tonBalance = userInfo?.balance / 10 ** 9;

  const wallet = useTonWallet();

  const [tonConnectUI] = useTonConnectUI();
  const dispatch = useDispatch();
  if (!userInfo) return;

  const handleAmountChange = (newAmount) => {
    const newAmountInNano = newAmount * 10 ** 9;
    const feeInNano = newAmountInNano * 0.01;
    const bruttoValueInNano = newAmountInNano + feeInNano;

    setBruttoValue(bruttoValueInNano / 10 ** 9);

    const newTx = {
      ...tx,
      messages: tx.messages.map((message) => {
        if (message.address === process.env.REACT_APP_MAIN_ADDRESS) {
          return { ...message, amount: newAmountInNano.toString() }; // Обновляем сумму основной транзакции
        } else if (message.address === process.env.REACT_APP_FEE_ADDRESS) {
          return { ...message, amount: feeInNano.toString() }; // Обновляем сумму комиссии
        }
        return message;
      }),
    };

    setTx(newTx);
  };
  const handleWithdrawChange = (amount) => {
    // Проверяем, чтобы значение не выходило за пределы допустимого диапазона
    const value = Math.max(0, Math.min(amount, userInfo.balance / 10 ** 9));
    //const isWD = userInfo.balance / 10 ** 9 <= 10 || withdrawAmount <= 10;
    const isWD = value < 10;
    setIsWithdrawDisabled(isWD);
    setWithdrawAmount(value);
  };

  const handleSendTransaction = async (dispatch) => {
    try {
      //console.log("tx", tx.messages);
      await tonConnectUI.sendTransaction(tx);
      // Операции после успешной отправки, если необходимо
      dispatch(
        setModalContent({
          isOpn: true,
          title: "Transaction sent successfully",
          message: `Please wait a minute for funds to be credited to your deposit`,
        })
      );
      onCloseButton();
      //console.log("Transaction sent successfully");
    } catch (error) {
      //console.error("Failed to send transaction:", error);
      // Здесь можно добавить логику для уведомления пользователя о возникшей ошибке
      // Например, обновление состояния или показ модального окна с ошибкой
      dispatch(
        setModalContent({
          isOpn: true,
          title: "Failed to send transaction",
          message: `Transaction rejected by user`,
        })
      );
    }
  };

  const handleSendWithdraw = async (dispatch, amount) => {
    try {
      await dispatch(makeWithdraw(amount * 10 ** 9));
      // Операции после успешной отправки, если необходимо
      dispatch(
        setModalContent({
          isOpn: true,
          title: "Transaction sent successfully",
          message: `You withdraw ${amount} TON. Please wait a minute for funds to be credited to your wallet`,
        })
      );
      onCloseButton();
      //console.log("Transaction sent successfully");
    } catch (error) {
      //console.error("Failed to send transaction:", error);
      // Здесь можно добавить логику для уведомления пользователя о возникшей ошибке
      // Например, обновление состояния или показ модального окна с ошибкой
      dispatch(
        setModalContent({
          isOpn: true,
          title: "Failed to send transaction",
          message: `Transaction rejected by user`,
        })
      );
    }
  };

  const onCloseButton = () => {
    setActiveTab("deposit");
    onClose();
  };

  return (
    <>
      <Transition in={isOpen} timeout={350} unmountOnExit={true}>
        {(state) => (
          <div className={`modal modal--${state}`}>
            <div className="modal-wrapper" /*onClick={onWrapperClick}*/>
              <div className="modal-content min-h-[250px]">
                <button
                  onClick={() => onCloseButton()}
                  className="modal-close-button"
                >
                  <Close />
                </button>
                <div className="flex border-b">
                  <button
                    onClick={() => setActiveTab("deposit")}
                    className={`flex-1 py-2 text-xl font-medium text-center transition duration-300 ease-in-out
                    ${
                      activeTab === "deposit"
                        ? "border-b-2 border-violet text-violet"
                        : "text-gray-500 hover:text-uf"
                    }`}
                  >
                    Deposit
                  </button>
                  <button
                    onClick={() => setActiveTab("withdraw")}
                    className={`flex-1 py-2 text-xl font-medium text-center transition duration-300 ease-in-out
                    ${
                      activeTab === "withdraw"
                        ? "border-b-2 border-violet text-violet"
                        : "text-gray-500 hover:text-uf"
                    }`}
                  >
                    Withdraw
                  </button>
                </div>
                {activeTab === "deposit" && (
                  <DepositPage
                    value={tx.messages[0].amount / 10 ** 9}
                    handleAmountChange={handleAmountChange}
                    bruttoValue={bruttoValue}
                    wallet={wallet}
                    dispatch={dispatch}
                    handleSendTransaction={handleSendTransaction}
                    tonConnectUi={tonConnectUI}
                  />
                )}
                {activeTab === "withdraw" && (
                  <WithdrawPage
                    tonBalance={tonBalance}
                    handleWithdrawChange={handleWithdrawChange}
                    withdrawAmount={withdrawAmount}
                    wallet={wallet}
                    tonConnectUI={tonConnectUI}
                    dispatch={dispatch}
                    handleSendWithdraw={handleSendWithdraw}
                    isWithdrawDisabled={isWithdrawDisabled}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </>
  );
};
export default SendModal;
