// import { configureStore } from "@reduxjs/toolkit";
// import modal from "./slices/modal/slice";
// import sendModal from "./slices/sendModal/slice";
// import lotto6x45 from "./slices/lotto6x45/slice";
// import profile from "./slices/profile/slice";
// import checkboxes from "./slices/checkboxes/slice";

// export const store = configureStore({
//   reducer: {
//     modal,
//     sendModal,
//     lotto6x45,
//     profile,
//     checkboxes,
//   },
// });
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // использует localStorage по умолчанию

import modal from "./slices/modal/slice";
import sendModal from "./slices/sendModal/slice";
import lotto6x45 from "./slices/lotto6x45/slice";
import profile from "./slices/profile/slice";
import checkboxes from "./slices/checkboxes/slice";

const rootReducer = combineReducers({
  modal,
  sendModal,
  lotto6x45,
  profile,
  checkboxes,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["profile", "lotto6x45"], // Вы можете добавить сюда любые slices, которые хотите сохранять
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
