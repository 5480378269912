import React from "react";
import MainPage from "./mainPage";
import toprightbg from "./assets/toprightbg.png";
import downleftbg from "./assets/downleftbg.png";
import downrightbg from "./assets/downrightbg.png";
import { TonConnectUIProvider } from "@tonconnect/ui-react";

function App() {
  return (
    <TonConnectUIProvider manifestUrl="https://gitlab.com/gritzzz/manifestjson/-/raw/main/manifest.json">
      <div
        className="min-h-screen w-full min-w-[358px] overflow-hidden"
        style={{
          background: `url(${toprightbg}),url(${downleftbg}),url(${downrightbg})`,
          backgroundColor: "#130D1A",
          backgroundPosition:
            "right -289px top -276px,left -492px bottom -357px,right -439px bottom -449px ",
          backgroundRepeat: "no-repeat, no-repeat,no-repeat",
          backgroundSize: "1047px 1048px,1458px 1458px, 1408px 1410px",
          backgroundAttachment: "fixed,fixed,fixed",
        }}
      >
        <MainPage />
      </div>
    </TonConnectUIProvider>
  );
}

export default App;
