import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  title: "",
  message: "",
};

export const sendModalSlice = createSlice({
  name: "sendModal",
  initialState,
  reducers: {
    setSendModalContent(state, action) {
      const { isOpn, title, message } = action.payload;
      state.isOpen = isOpn;
      state.title = title ?? "";
      state.message = message ?? "";
    },
  },
});

export const { setSendModalContent } = sendModalSlice.actions;
export default sendModalSlice.reducer;
