import AmountButton from "./amountButton";

const WithdrawPage = ({
  tonBalance,
  handleWithdrawChange,
  withdrawAmount = 0,
  wallet,
  tonConnectUI,
  dispatch,
  handleSendWithdraw,
  isWithdrawDisabled,
}) => {
  return (
    <div className="send-tx-form">
      <div className="mt-5 pl-5 flex gap-1">
        <AmountButton
          amount={10}
          onClick={handleWithdrawChange}
          isDisabled={tonBalance < 10}
        />
        <AmountButton
          amount={20}
          onClick={handleWithdrawChange}
          isDisabled={tonBalance < 20}
        />
        <AmountButton
          amount={50}
          onClick={handleWithdrawChange}
          isDisabled={tonBalance < 50}
        />
        <AmountButton
          amount={tonBalance}
          isDisabled={tonBalance < 10}
          max={true}
          onClick={handleWithdrawChange}
        />
      </div>
      <div className="input-group">
        <label htmlFor="myInput">Withdraw (min 10 TON)</label>
        <input
          type="number"
          id="amount"
          value={withdrawAmount}
          min="0"
          max={tonBalance}
          onChange={(e) => handleWithdrawChange(e.target.value)}
        />
        <p className="input-hint">
          *You will receive {(withdrawAmount * 0.99).toFixed(2)}
          TON as a 1% fee will be withheld
        </p>
      </div>
      {wallet ? (
        <button
          disabled={isWithdrawDisabled}
          onClick={() => handleSendWithdraw(dispatch, withdrawAmount)}
          className="shadow-outer text-violet bg-acid hover:bg-pressedviolet hover:text-pressedtextviolet focus:ring-4 focus:ring-violet font-medium text-m rounded-lg
                    px-2 py-2 focus:outline-none lg:px-5 lg:py-[10px] disabled:shadow-none disabled:bg-pressedviolet disabled:text-uf"
        >
          Transfer
        </button>
      ) : (
        <button onClick={() => tonConnectUI.openModal()}>
          Connect wallet to send the transaction
        </button>
      )}
    </div>
  );
};

export default WithdrawPage;
