import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  checkboxes: Array(45).fill(false),
  errString: "",
  isDisabled: true,
};

export const checkboxesSlice = createSlice({
  name: "checkboxes",
  initialState,
  reducers: {
    setErrString(state, action) {
      state.errString = action.payload;
    },
    setCheckboxes(state, action) {
      state.checkboxes = action.payload;
    },
  },
});

export const { setErrString, setCheckboxes } = checkboxesSlice.actions;
export default checkboxesSlice.reducer;
