import React, { useCallback, useEffect, useRef, useState } from "react";
import { TonProofApi } from "./api/TonProofApi";
import { useTonConnectUI } from "@tonconnect/ui-react";
import useInterval from "./hooks/useInterval";
import Demopage from "./demopage";
import Lotto6x45 from "./lotto6x45";

export const TonProof = () => {
  const firstProofLoading = useRef(true);

  const [authorized, setAuthorized] = useState(false);
  const [tonConnectUI] = useTonConnectUI();

  const checkCookie = async () => {
    const checkInitCookie = await TonProofApi.checkInitialToken();
    // console.log("check", checkInitCookie);
    return checkInitCookie;
  };

  const recreateProofPayload = useCallback(async () => {
    if (firstProofLoading.current) {
      tonConnectUI.setConnectRequestParameters({ state: "loading" });
      firstProofLoading.current = false;
    }

    const payload = await TonProofApi.generatePayload();

    if (payload) {
      await checkCookie();
      tonConnectUI.setConnectRequestParameters({
        state: "ready",
        value: payload,
      });
    } else {
      tonConnectUI.setConnectRequestParameters(null);
    }
  }, [tonConnectUI, firstProofLoading]);

  if (firstProofLoading.current) {
    recreateProofPayload();
  }

  useInterval(recreateProofPayload, TonProofApi.refreshIntervalMs);

  useEffect(() => {
    tonConnectUI.onStatusChange(async (w) => {
      //const cook = await checkCookie();
      // console.log("sdsds", cook);
      if (!w) {
        setAuthorized(false);
        return;
      }

      if (w.connectItems?.tonProof && "proof" in w.connectItems.tonProof) {
        const authorized = await TonProofApi.checkProof(
          w.connectItems.tonProof.proof,
          w.account
        );

        if (!authorized && !checkCookie()) {
          console.log("sdsds");
          tonConnectUI.disconnect();
          return;
        }
      }
      setAuthorized(true);
    });
  }, [tonConnectUI]);
  //console.log(authorized);
  return <>{authorized ? <Lotto6x45 /> : <Demopage />}</>;
};
