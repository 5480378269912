import tonSymbolViolet from "../../../assets/ton_symbolviolet.png";
import tonSymbol from "../../../assets/ton_symbol.png";
import { useState } from "react";

const AmountButton = ({ amount, max, onClick, isDisabled = false }) => {
  const [imageSrc, setImageSrc] = useState(tonSymbolViolet);

  return (
    <button
      disabled={isDisabled}
      onMouseEnter={() => setImageSrc(tonSymbol)}
      onMouseLeave={() => setImageSrc(tonSymbolViolet)}
      onClick={() => onClick(amount)}
      className="shadow-outer flex gap-1 text-violet bg-white hover:bg-pressedviolet hover:text-pressedtextviolet  font-medium text-m rounded-lg
                      px-3 py-2 focus:outline-none  lg:py-[10px] disabled:shadow-none disabled:bg-gray-300 disabled:text-uf"
    >
      <div className="grid grid-flow-col gap-1">
        {amount}
        <img
          className="self-center"
          src={imageSrc}
          alt="TON"
          style={{
            width: "20px",
            height: "20px",
            minWidth: "20px",
            minHeight: "20px",
          }}
        />
        {max && <>max</>}
      </div>
    </button>
  );
};

export default AmountButton;
