import React, { useEffect } from "react";

import MakedBets from "./components/makedBets";
import LastEventsTable from "./components/lastEvents";

import Rewards from "./components/rewards";
import Basement from "./components/basement";
import UserInfo from "./components/userInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentRound,
  selectWinEvents,
} from "./redux/slices/lotto6x45/selectors";
import { getCurrentRound, getWinEvents } from "./redux/slices/lotto6x45/slice";
import CheckboxGrid from "./components/checkboxGrid";
import { useTonConnectUI } from "@tonconnect/ui-react";
import WinTable from "./components/winTable";
import { handleCheckboxToggle } from "./functions/checkboxGridFunctions";
import { setCheckboxes } from "./redux/slices/checkboxes/slice";
import { selectCheckboxes } from "./redux/slices/checkboxes/selectors";

const Demopage = () => {
  const checkboxes = useSelector(selectCheckboxes);
  const winEvents = useSelector(selectWinEvents);
  const currentRound = useSelector(selectCurrentRound);
  const dispatch = useDispatch();
  const tonConnectUI = useTonConnectUI();

  useEffect(() => {
    dispatch(getCurrentRound());
    dispatch(getWinEvents());
  }, []);

  return (
    <div className="">
      <UserInfo USDTbalance={null} minimalBet={1} />

      <div className="2xl:flex grid flex-wrap m-auto justify-evenly pt-5 gap-4 mx-2 lg:pt-10 lg:gap-20 lg:mx-auto">
        <div className="flex flex-col relative gap-4 overflow-visible 2xl:mb-60 lg:min-w-[733px] lg:w-[733px]">
          <CheckboxGrid
            demo={true}
            checkboxes={checkboxes}
            handleCheckboxToggle={(index) =>
              handleCheckboxToggle(index, checkboxes, setCheckboxes, dispatch)
            }
            currentRound={currentRound?.roundNum}
            roundFinishTime={currentRound?.finishDatetime}
            dispatch={dispatch}
            getCurrentRound={() => getCurrentRound()}
            tonConnectUI={() => tonConnectUI()}
          />

          <MakedBets currentBets={[]} />
        </div>
        <div className="flex flex-col relative gap-4 ">
          <Rewards minimalBet={1} bonuses={currentRound?.bonuses} />
          <WinTable />
          <LastEventsTable winEvents={winEvents} />
        </div>
      </div>
      <Basement />
    </div>
  );
};

export default Demopage;
