import { setCheckboxes } from "../redux/slices/checkboxes/slice";
import { getUserBets, makeBet } from "../redux/slices/lotto6x45/slice";
import { setModalContent } from "../redux/slices/modal/slice";
import { getUserInfo } from "../redux/slices/profile/slice";

const getSelectedCount = (checkboxes) =>
  checkboxes.filter((checkbox) => checkbox).length;

export const handleCheckboxToggle = (
  index,
  checkboxes,
  setCheckboxes,
  dispatch
) => {
  const newCheckboxes = checkboxes.map((checked, i) => {
    if (i === index) return !checked;
    return checked;
  });
  dispatch(setCheckboxes(newCheckboxes));
};

export const updateIsDisabled = (
  checkboxes,
  dispatch,
  setIsDisabled,
  maxSelectedCheckboxes,
  errString
) => {
  const selectedCount = getSelectedCount(checkboxes);
  setIsDisabled(selectedCount !== maxSelectedCheckboxes);
  // if (errString !== "Not enough sETH for minimal bet") {
  // if (selectedCount !== maxSelectedCheckboxes) {
  //   dispatch(setErrString("Please, take 6 numbers"));
  // } else {
  //   dispatch(setErrString(""));
  // }
  // }
};

export const handleSubmit = async (checkboxes, dispatch) => {
  try {
    const selectedCount = getSelectedCount(checkboxes);
    if (selectedCount === 6) {
      const selectedIndexes = checkboxes.reduce((acc, isChecked, index) => {
        if (isChecked) {
          acc.push(index + 1);
        }
        return acc;
      }, []);
      await dispatch(makeBet(selectedIndexes));
      const clearCheckboxes = new Array(45).fill(false);
      dispatch(setCheckboxes(clearCheckboxes));
      dispatch(getUserBets());
      dispatch(getUserInfo());
      dispatch(
        setModalContent({
          isOpn: true,
          title: "Bet is placing",
          message: `${selectedIndexes}`,
        })
      );
    }
  } catch (error) {
    //console.error("Error handleSubmit:", error);
  }
};
