import React, { useEffect, useState } from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import kuvsh from "../assets/kuvshinki.png";
import group78 from "../assets/Group 78.png";

const LastEventsTable = ({ winEvents }) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    // Обновляем 'now' каждую минуту
    const intervalId = setInterval(() => {
      setNow(new Date());
    }, 60000); // обновлять раз в минуту

    // Очистка ресурсов при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []);
  if (!winEvents) return;
  return (
    <div className="flex flex-col relative">
      <img
        src={kuvsh}
        alt="KD"
        className="absolute  top-[-120px] right-[-70px] z-20 hidden lg:block"
      />
      <img
        src={group78}
        alt="g78"
        className="absolute  bottom-[-134px] right-[-106px] hidden lg:block"
      />
      <h1 className="font-lg400 text-acid text-2xl leading-7 mb-3">
        Last Events
      </h1>

      <div className="bg-uf rounded-2xl p-1 z-10 lg:p-8">
        <table className="w-full">
          <thead>
            <tr>
              <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                Time
              </th>
              <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                User Wallet
              </th>
              <th className="p-1 lg:p-2 text-left font-jost font-thin text-s lg:text-xl lg:leading-7 text-pressedtextviolet">
                Event
              </th>
            </tr>
          </thead>

          <tbody>
            {winEvents.map((event, index) => (
              <tr key={index}>
                <td className="font-krona p-1 text-xs lg:p-2 lg:text-sm lg:leading-4 text-white">
                  {formatDistanceToNow(parseISO(event.eventDatetime), {
                    addSuffix: true,
                    baseDate: now,
                  })}
                </td>
                <td className="font-krona p-1 text-xs lg:p-2 lg:text-sm lg:leading-4 text-white">
                  {event.userTonAddress.slice(0, 6)}...
                  {event.userTonAddress.slice(-6)}
                </td>
                <td className="font-krona p-1 text-xs lg:p-2 lg:text-sm lg:leading-4 text-white">
                  {event.message}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LastEventsTable;
