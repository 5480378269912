import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

// Обработчик запросов для логирования
instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Обработчик ответов для логирования
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
class TonProofApiService {
  refreshIntervalMs = 4 * 60 * 1000;

  // constructor() {
  //   this.initialize();
  //   // if (!this.checkInitialToken()) {
  //   //   this.generatePayload();
  //   // }
  // }

  async checkInitialToken() {
    let response = await this.getAuth();
    let status = response.status;
    // console.log("Initial status:", status);

    if (status !== 200) {
      await this.tokenRefresh();
      response = await this.getAuth();
      status = response.status;
      // console.log("Refreshed status:", status);
    }

    return status === 200;
  }

  getAuth = async () => {
    try {
      const response = await instance.get("profile/me");
      return response;
    } catch (error) {
      //console.error("Error fetching authentication status:", error);
      return { status: error.response ? error.response.status : 500 };
    }
  };

  async tokenRefresh() {
    try {
      const response = await instance.post("token/refresh");
      return response;
    } catch (error) {
      return { status: error.response ? error.response.status : 500 };
    }
  }

  async generatePayload() {
    try {
      const response = await instance.get(`token/generate-ton-proof-payload`);
      return { tonProof: response.data.message };
    } catch (error) {
      // console.error("generatePayload error:", error);
      return null;
    }
  }

  async checkProof(proof, account) {
    try {
      //  console.log("account", account);
      const reqBody = {
        address: account.address,
        network: account.chain,
        public_key: account.publicKey,
        proof: {
          ...proof,
          state_init: account.walletStateInit,
        },
      };
      //    console.log("rb", reqBody);
      const response = await instance.post(`token/auth-by-ton-proof`, reqBody);
      //   console.log("response", response.data);
      //   const data = response.data;
      return response.status === 200;
    } catch (error) {
      // console.error("checkProof error:", error);
      return false;
    }
  }
}

export const TonProofApi = new TonProofApiService();
